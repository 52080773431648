<template>
    <v-card class="card-shadow">
        <div class="card-title-wrapper pa-6">
            <v-row>
                <v-col cols="12">
                    <v-card-title class="text-h1 font-weight-600 pa-0 mb-4">Notifications</v-card-title>
                    <v-card-subtitle class="text-subtitle-1 pa-0">You can adjust the notifications you will receive.</v-card-subtitle>
                </v-col>
            </v-row>
        </div>
        <v-card-text v-if="user && user.notifications" class="pa-6">
            <v-row>
                <v-col cols="12">
                    <v-switch @change="saveNotifications()" v-model="user.notifications.emailNotifications" inset hide-details label="Receive email announcements" color="#6F70FF" class="switch mb-6"> </v-switch>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { makeGetMixin } from 'feathers-vuex';

export default {
    data() {
        return {
        };
    },
    mixins: [
        makeGetMixin({
        service: 'users',
        name: 'user',
        id: 'userId',
        queryWhen: 'userId',
        watch: true
        })
    ],
    methods: {
        async saveNotifications() {
            try {
                await this.user.save()
                this.showSuccess('Notifications successfully saved!')
            } catch (error) {
                this.showError(error)
            }
        },
        ...mapActions('app', ['showSuccess', 'showError'])
    },
    computed: {
        userId() {
            return this.authUser && this.authUser.id
        },
        ...mapGetters('apiAuth', { authUser: 'user' })
    }
};
</script>
