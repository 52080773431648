<template>
    <div v-if="form && form.sections && !form.sections.length" class="text-center pa-3">
        <h1 class="text-h1 font-weight-600 text-black text-center mb-2 pt-5">No preview available</h1>
        <p class="text-black font-size-root text-center font-weight-thin mb-2">Your form is empty. You can view a preview of your form once a section has been added.</p>
        <v-img class="my-10 md-max-width" :src="illustration" max-height="300" contain />
        <v-btn :to="`/edit-form/${formId}`" elevation="0" height="43" class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm" color="#6F70FF"> Create first section </v-btn>
    </div>
    <div v-else-if="form && form.sections && form.sections.length">
        <v-row class="d-flex justify-center">
            <v-col sm="10" md="10" lg="10" xl="8" class="pt-3">
                <v-card class="card-shadow">
                    <div class="card-title-wrapper pa-6 py-4">
                        <v-card-title class="text-h1 font-weight-600 mb-6 pa-0">{{ form.name }} - {{ form.sections[currentPage - 1].title }} </v-card-title>
                        <v-card-subtitle class="text-subtitle-2 text-light subtitle-2 pa-0">{{ form.sections[currentPage - 1].description }} </v-card-subtitle>
                    </div>
                </v-card>
            </v-col>
            <v-col v-for="(question, index) in form.sections[currentPage - 1].questions" :key="index" sm="10" md="10" lg="10" xl="8">
                <v-card class="card-shadow">
                    <div class="card-title-wrapper pa-6">
                        <div class="d-flex justify-space-between">
                            <label class="label-color font-weight-600 subtitle-2 mb-2 d-block">{{ index + 1 }}. {{ question.name }}</label>
                            <v-tooltip v-if="question.guide.length" bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <feather type="info" class="text-primary" size="16" stroke-width="1.5" v-bind="attrs" v-on="on"></feather>
                                </template>
                                <span>{{ question.guide }}</span>
                            </v-tooltip>
                        </div>
                        <div class="question-image">
                            <img v-if="question.image" :src="`${$feathersConnectionString}/${question.image.url}`" alt="Image" class="mb-8" />
                        </div>
                        <v-text-field v-if="question.type === 'Text'" hide-details outlined class="input-style font-size-input text-color-gray mt-0" placeholder="Your answer"> </v-text-field>
                        <v-select
                            v-if="question.type === 'Dropdown'"
                            :items="question.options"
                            item-text="value"
                            single-line
                            hide-details
                            outlined
                            class="input-style select-style text-color-gray"
                            placeholder="Select option">
                        </v-select>
                        <div v-if="question.type === 'Checkbox'">
                            <v-checkbox
                                v-for="(option, index) in question.options"
                                :key="index"
                                color="#6F70FF"
                                class="my-1 checkbox-custom checkbox-thinner"
                                :value="option.value"
                                hide-details
                                multiple>
                                <template v-slot:label>
                                    <span class="text-body-2 ls-0">{{ option.value }}</span>
                                </template>
                            </v-checkbox>
                        </div>
                        <v-radio-group v-if="question.type === 'Single Choice'">
                            <v-radio v-for="(option, index) in question.options" :key="index" color="#6F70FF" :value="option.value" class="checkbox-custom">
                                <template v-slot:label>
                                    <span class="text-body-2 ls-0">{{ option.value }}</span>
                                </template>
                            </v-radio>
                        </v-radio-group>
                        <Uploader
                            @onRemove.stop=""
                            v-if="question.type === 'Image'"
                            class="image"
                            width="200px"
                            :accepts="['image/png', 'image/jpeg', 'image/jpg', 'image/webp']"
                            :maxSize="4194304"
                            :context="'general'" />
                        <Uploader
                            @onRemove.stop=""
                            v-if="question.type === 'File'"
                            class="image"
                            width="200px"
                            :accepts="[
                                'application/pdf',
                                'text/csv',
                                'application/msword',
                                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                'application/json',
                                'application/vnd.oasis.opendocument.spreadsheet',
                                'application/vnd.oasis.opendocument.text',
                                'application/vnd.oasis.opendocument.presentation',
                                'application/vnd.ms-powerpoint',
                                'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                                'application/rtf',
                                'text/plain',
                                'application/vnd.ms-excel',
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                'application/xml'
                            ]"
                            :maxSize="4194304"
                            :context="'general'" />
                        <v-menu v-if="question.type === 'Date'" ref="menu" :close-on-content-click="false" transition="scale-transition" offset-y value="" min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field hide-details readonly outlined class="input-style font-size-input text-color-gray" v-bind="attrs" v-on="on" placeholder="Select date">
                                    <template slot="append">
                                        <v-btn :ripple="false" icon max-height="0.875rem">
                                            <feather type="calendar" class="text-darker" size="18" stroke-width="1.5"></feather>
                                        </v-btn>
                                    </template>
                                </v-text-field>
                            </template>
                            <v-date-picker no-title scrollable color="#6F70FF"></v-date-picker>
                        </v-menu>
                        <v-row v-if="question.type === 'Formula'" class="mb-2">
                            <v-col v-for="(option, j) in question.options" cols="6" :key="j" class="pb-0">
                                <label v-if="option.value.length" class="label-color font-weight-600 subtitle-2 mb-2 d-block">{{ question.options[j].value }}</label>
                                <label v-else class="label-color font-weight-600 subtitle-2 mb-2 d-block">Value</label>
                                <v-text-field
                                    :type="question.format === 'Text' ? 'text' : 'number'"
                                    hide-details
                                    outlined
                                    class="input-style font-size-input text-color-gray mt-0"
                                    placeholder="Your answer">
                                    <template slot="prepend">
                                        <v-icon v-if="question.format === 'Number'" size="24">mdi-numeric</v-icon>
                                        <span v-if="question.format === 'Rand'" class="font-weight-600 subtitle-2 label-color">R</span>
                                        <v-icon v-if="question.format === 'US Dollar'" size="24">mdi-currency-usd</v-icon>
                                        <v-icon v-if="question.format === 'Euro'" size="24">mdi-currency-eur</v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <div v-if="question.type === 'Signature'" class="container">
                          <div class="container">
                            <VueSignaturePad
                              class="signature"
                              width="320px"
                              height="240px"
                              :ref="`signaturePad_${currentPage - 1}_${index}`"
                              :id="`signaturePad_${currentPage - 1}_${index}`"
                              :key="`signaturePad_${currentPage - 1}_${index}`"
                              :options="{ onEnd: () => onEnd(index) }" 
                            />
                          </div>
                          <div class="buttons">
                            <v-btn
                                elevation="0"
                                class="font-weight-600 btn-block text-capitalize btn-ls btn-outline-primary rounded-sm"
                                color="transparent"
                                @click="clear(index)">
                                Clear
                            </v-btn>
                            <v-btn
                                elevation="0"
                                class="ml-4 font-weight-600 btn-block text-capitalize btn-ls btn-outline-primary rounded-sm"
                                color="transparent"
                                @click="undo(index)">
                                Undo
                            </v-btn>
                          </div>
                        </div>
                        <div v-if="question.comments.label.length" class="mt-4">
                            <label for="questionComments" class="label-color font-weight-600 subtitle-2 mb-2 d-block">{{ question.comments.label }}</label>
                            <v-textarea
                                id="questionComments"
                                v-model="question.comments.comment"
                                rows="1"
                                hide-details
                                outlined
                                class="input-style font-size-input text-color-gray mt-0"
                                placeholder="Additional comments">
                            </v-textarea>
                        </div>
                    </div>
                </v-card>
            </v-col>
            <v-col cols="8" class="my-4 d-flex justify-end">
                <v-pagination prev-icon="fa fa-angle-left" next-icon="fa fa-angle-right" class="pagination" color="#6F70FF" v-model="currentPage" :length="form.sections.length" circle></v-pagination>
            </v-col>
            <!-- <pre>{{ form }}</pre> -->
        </v-row>
    </div>
</template>

<script>
import Uploader from '@/components/UploaderV2/Uploader.vue';

export default {
    components: {
        Uploader
    },
    props: {
        form: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            illustration: require('@/assets/img/illustrations/undraw_Add_post_re_174w.svg'),
            currentPage: 1
        };
    },
    methods: {
        undo(index) {
            this.$refs[`signaturePad_${this.currentPage - 1}_${index}`][0].undoSignature();
        },
        clear(index) {
            this.$refs[`signaturePad_${this.currentPage - 1}_${index}`][0].clearSignature();
        },
        onEnd(index) {
            this.$refs[`signaturePad_${this.currentPage - 1}_${index}`][0].saveSignature('image/png', 0.5);
        }
    },
    computed: {
        sectionsLength() {
            if (this.form.sections && this.form.sections.length) {
                return this.form.sections.length;
            } else {
                return 1;
            }
        },
        formId() {
            return this.$route.params && this.$route.params.id;
        }
    }
};
</script>
<style scoped lang="scss">
.image {
    max-width: 16rem !important;
    margin: auto;
}

.question-image {
    display: flex;
    justify-content: center;

    img {
        max-width: 20rem !important;
        border-radius: 1.5rem;
    }
}

.signature {
    border: double 2px transparent;
    border-radius: 10px;
    background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #000000, #000000);
    background-origin: border-box;
    background-clip: content-box, border-box;
    margin: 0 auto;
}

.container {
    width: 100%;
    padding: 8px 16px;
}

.buttons {
    display: flex;
    gap: 8px;
    justify-content: center;
    margin-top: 8px;
}

@media screen and (max-width: 960px) {
    .md-max-width {
        width: 500px;
        max-width: 500px;
    }
}
</style>
