<template>
    <v-card class="card-shadow">
        <div class="card-title-wrapper pa-6">
            <v-row>
                <v-col cols="8">
                    <v-card-title class="text-h1 font-weight-600 pa-0 mb-4">My Upcoming Forms</v-card-title>
                    <v-card-subtitle class="text-subtitle-1 pa-0">These forms have not been released yet and will become available at the designated date and time</v-card-subtitle>
                </v-col>
            </v-row>
        </div>

        <v-card-text class="pa-6">
            <v-row>
                <v-col cols="12">
                    <v-data-table v-if="upcomingFormsParsed.length" :headers="upcomingHeaders" :items="upcomingFormsParsed" hide-default-footer>
                        <template v-slot:[`item.form_name`]="{ item }">
                            <div class="d-flex align-center">
                                <feather type="file-text" class="text-grey" size="1.2rem" stroke-width="1.2"></feather>
                                <v-btn class="font-weight-600 text-capitalize px-2" text>
                                    {{ item.form_name }}
                                </v-btn>
                            </div>
                        </template>
                        <template v-slot:[`item.form_release_date`]="{ item }">
                            <v-chip color="#6F70FF" class="text-white text-caption font-weight-600">
                                {{ item.form_release_date }}
                            </v-chip>
                        </template>
                        <template v-slot:[`item.form_users`]="{ item }">
                            <div>
                                <v-tooltip v-for="(user, i) in item.form_users.slice(0, 3)" :key="i" bottom>
                                    <template #activator="tooltip">
                                        <v-btn class="avatars-group-item" icon v-on="{ ...tooltip.on }">
                                            <v-avatar size="33" color="#F3F4FD">
                                                <span v-if="!user.avatar" class="text-primary text-h5 font-weight-600">{{ userInitials(user) }}</span>
                                                <img v-else :src="`${$feathersConnectionString}/${user.avatar.url}`" alt="User" />
                                            </v-avatar>
                                        </v-btn>
                                    </template>
                                    <span>{{ user.first_name }} {{ user.last_name }}</span>
                                </v-tooltip>
                                <v-tooltip v-if="item.form_users.length > 3" bottom>
                                    <template #activator="tooltip">
                                        <v-btn v-if="item.form_users.length > 3" class="avatars-group-item" icon v-on="{ ...tooltip.on }">
                                            <v-avatar size="33" color="#F3F4FD">
                                                <span class="text-primary text-h5 font-weight-600">+{{ item.form_users.length - item.form_users.slice(0, 3).length }}</span>
                                            </v-avatar>
                                        </v-btn>
                                    </template>
                                    <div>
                                        <span v-for="(user, index) in item.form_users.slice(3, item.form_users.length)" :key="index" class="font-weight-600 text-capitalize">
                                            {{ user.first_name }} {{ user.last_name }}<span v-if="index + 3 !== item.form_users.length - 1">,</span>
                                        </span>
                                    </div>
                                </v-tooltip>
                            </div>
                        </template>
                    </v-data-table>
                    <v-card v-else>
                        <v-card-title class="ml-4">
                            <span class="text-muted text-caption ls-0 font-weight-600">You currently have 0 upcoming forms.</span>
                        </v-card-title>
                    </v-card>
                </v-col>
            </v-row>
            <div class="mt-4 d-flex justify-end">
                <v-pagination prev-icon="fa fa-angle-left" next-icon="fa fa-angle-right" class="pagination" color="#6F70FF" v-model="currentPage" :length="totalPagesLength" circle></v-pagination>
            </div>
            <!-- <pre>{{ upcomingForms }}</pre> -->
        </v-card-text>
    </v-card>
</template>

<script>
/* eslint-disable vue/no-side-effects-in-computed-properties */
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
    components: {},
    data() {
        return {
            currentPage: 1,
            perPage: 10,
            totalPages: 1,
            upcomingForms: [],
            upcomingHeaders: [
                { text: 'Form Name', value: 'form_name' },
                { text: 'Schedule', value: 'form_type' },
                { text: 'Release Date', value: 'form_release_date' },
                { text: 'User(s)', value: 'form_users' }
            ]
        };
    },
    mounted() {
        this.upcoming();
    },
    methods: {
        retrieveUser(userId) {
            const foundUser = this.users.find((user) => user.id === userId);

            if (foundUser === undefined) {
                return false;
            }

            return foundUser;
        },
        userInitials(user) {
            const initials = (user.first_name + ' ' + user.last_name).match(/\b\w/g) || [];

            return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
        },
        createdAt(time) {
            var d = new Date(time);
            return this.$moment(d).calendar();
        },
        async upcoming() {
            if (this.authUser.role === 'Admin' || this.authUser.role === 'Manager' || this.authUser.role === 'GlobalManager') {
                const result = await this.$store.dispatch('overview/find', {
                    query: {
                        manager_id: this.authUser.id
                    }
                });

                if (result[1].length) {
                    this.upcomingForms = result[1];
                }
            } else {
                const result = await this.$store.dispatch('overview/find', {
                    query: {
                        user_id: this.authUser.id
                    }
                });

                if (result[1].length) {
                    this.upcomingForms = result[1];
                }
            }
        }
    },
    computed: {
        upcomingFormsParsed: function () {
            if (!this.upcomingFormsPaginated) return [];
            return this.upcomingFormsPaginated.map((form) => {
                const d = {
                    form_name: form.name,
                    form_release_date: form.schedule.type ? moment(form.schedule.releaseDate).format('Do MMMM YYYY, HH:mm') : 'None',
                    form_users: form.users,
                    form_type: form.schedule.type
                };
                return d;
            });
        },
        upcomingFormsPaginated() {
            const firstIndex = (this.currentPage - 1) * this.perPage;
            const lastIndex = this.perPage * this.currentPage;

            if (this.upcomingForms && this.upcomingForms.length) {
                return this.upcomingForms.slice(firstIndex, lastIndex);
            } else {
                return [];
            }
        },
        totalPagesLength() {
            this.totalPages = Math.ceil(this.upcomingForms.length / this.perPage);

            if (this.upcomingForms.length / this.totalPages === this.perPage) {
                this.currentPage = 1;
            }

            return this.totalPages;
        },
        usersParams() {
            return {
                query: {}
            };
        },
        ...mapGetters('apiAuth', { authUser: 'user' })
    }
};
</script>
<style lang="scss" scoped>
.ps-customizer-area {
    max-height: 26rem;
}
</style>
